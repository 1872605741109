
body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1e2c38;
}
/* global-styles.css */

/* Scrollbar for body */
body::-webkit-scrollbar {
  width: 8px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #0D1117;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #161B22;
  border-radius: 5px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width : 600px)
{
  body::-webkit-scrollbar {
    display: none;

  }
}
.mainContainer {
  height: 95vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: top;
  gap: 0px;
  font-family: "Clash Grotesk";
}

.sideBar_maincontainer{
  position: fixed;
  background-color: #14212e;
  display: inline-flex;
  height: 85vh;
  width: 15vw;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  transform: translate(0, 10vh);
  z-index: 99;
}

.noti_maincontainer {
  width: 85vw;
  /* height: 85vh; */
  background-color: transparent;
  display: flex;
  justify-content: center;
  transform: translate(15vw, 10vh);
  overflow: y;
}

.noti_maincontainer2 {
  width: 85vw;
  /* height: 85vh; */
  background-color: transparent;
  display: flex;
  justify-content: left;
  margin-left: 5vw;
  transform: translate(15vw, 10vh);
  overflow: y;
}

.noti_batchPrompt {
  width: 50%;
    height: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.batchPrompt_header {
  height: 70px;
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
}
.batchPrompt_subHead{
  font-size: 20px;
  font-style: italic;
  color: grey;
  position: relative;
  top: 34px;
  left: -159px;
  letter-spacing: 1px;
  font-weight: 300;
}
.batchPrompt_header .text {
  background: linear-gradient(90deg, #00c2ff, #fa00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.batchPrompt_options {
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 55px;
}
.batchPrompt_shareExp {
  height: 70px;
  width: 77%;
  background: linear-gradient(90deg, #4ca5ff, #b573f8);
  border-radius: 20px;
  display: flex;
  text-align: center;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
}
.batchPrompt_shareExp:hover {
  background-color: #04aee2;
}
.bachPrompt_option1 {
  height: 70px;
  width: 70%;
  background-color: #161b22;
  border: 1px solid rgb(73, 73, 73);
  box-shadow: 3px 3px 3px #0a0c11;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 50px;
  font-size: 17px;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
}
.bachPrompt_option1:hover {
  background-color: #1d2127;
  border-radius: 5px;
}
.batchPrompt_update {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #474747;
  background-color: #0d1014;
  flex-direction: column;
}
.noti_check {
  width: 50%;
  height: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.noti_checkHead {
  font-size: 29px;
  /* font-style: italic; */
  color: rgb(167, 167, 167);
  letter-spacing: 2px;
  font-weight: 650;
  background: linear-gradient(90deg, #00c2ff, #fa00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}
.noti_checkprompt {
  height: 150px;
  width: 70%;
  color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
  font-style: italic;
  background-color: #161b22;
  margin-top: 30px;
  border-radius: 20px;
}
.noti_checkLink {
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
  letter-spacing: 1px;
  color: rgb(184, 184, 184);
  text-decoration: none;
}
.noti_checkLink:hover {
  color: white;
}
.batchPrompt_notiCall {
  display: none;
}

.notiWindow
{
  height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;

}

.drawerIn
{
  color: red;
}
.drawer_controls
{
  height: 50px;
  width: 100%;
}
.close_drawer{
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close_drawer::before{
  content: '';
  height: 20px;
  width: 2px;
  background-color: white;
  display: flex;
  transform: rotate(45deg);
  position: absolute;
  right: 25px;
}
.close_drawer::after{
  content: '';
  height: 20px;
  width: 2px;
  background-color: white;
  display: flex;
  transform: rotate(-45deg);
  position: absolute;
  right: 25px;
}
.drawer_header{
  font-size: 19px;
  color: #fff;
  letter-spacing: 3px;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.drawer_notiGrid{
  height: 75%;
  width: 99%;
  margin-top: 40px;
  display: flex;
  overflow: auto;
  border: 1px solid grey;
  border-radius: 10px;
}
@media (max-width: 500px) {
  .mainContainer {
    gap: 0px;
  }
  .batchHead {
    font-size: 25px;
  }
  #ShareExp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #ShareExpP {
    padding-left: 18px;
  }
  .mainpage_container {
    display: none;
  }
  .batchPrompt_header {
    width: 100%;
  }
  .noti_batchPrompt {
    width: 100%;
  }
  .batchPrompt_shareExp {
    width: 83%;
  }
  .batchPrompt_header {
    font-size: 27px;
  }
  .noti_maincontainer {
    display: none;
  }

  .noti_check {
    width: 100%;
    height: 92vh;
  }
  .noti_checkprompt {
    width: 97%;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
  }
  .batchPrompt_notiCall {
    height: 43px;
    width: 43px;
    background-color: rgb(53, 53, 53);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 4%;
    right: 8%;
    box-shadow: 2px 2px 2px rgb(12, 7, 7);
    cursor: pointer;
    border: none;
  }
  .batchPrompt_subHead{
    font-size: 20px;
    font-style: italic;
    color: grey;
    position: relative;
    top: 34px;
    left: -59px;
    letter-spacing: 1px;
    font-weight: 400;
  }
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Clash Grotesk'; /*a name to be used later*/
  src: url('../public/fonts/ClashGrotesk-Variable.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Alexandria'; /*a name to be used later*/
  src: url('../public/fonts/Alexandria-Medium.ttf'); /*URL to font*/
}

.wholePage {
  height: 100vh;
}