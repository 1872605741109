.profile_window{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 85vw;
    padding: 10vh;
}

.profile_container{
    width: 65vw;
    height: 70vh;
    overflow: auto;
    background-color: white;
    border: 1px solid rgb(104, 104, 104);
    border-radius: 25px;
    text-align: center;
    align-items: center;
}
/* CSS for checkboxes */
.checkboxes {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 3vh;
    width: 70vw;
    height: 10vh;
    justify-content : center;
    font-size: 2vh;
    align-items: center;
}

.flat {
    width: 100%;
    display: flex;
    font-size: 1.4vh;
    justify-content: space-between; /* Aligns items with maximum space between them */
    align-items: center; /* Centers items vertically */
}

.leftHeader {
    /* Optional: If you want some styling for left header */
    margin-left: 1vw; /* Adjust as needed */
}

.rightHeader {
    margin-right: 1vw;
    /* Optional: If you want some styling for right header */
}
.checkboxes2 {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 10px;
    justify-content : center;
    font-size: 2vh;
    align-items: center;
    position: fixed;
    background-color: white;
    border-radius: 10px;
    width: 65vw;
    height: 10vh;
    overflow: auto;
    z-index: 97;
}

.checkboxes2 label {
    display: block;
}

.checkboxes label {
    display: block;
}

.subtopics{
    margin-bottom: 30px;
    border-radius: 8px; /* Rounded corners */
    border: 2px solid #ccc; /* Border color */
    background-color: #f0f0f0; /* Background color */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* CSS for domain sections */

.subtopic-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1vh;
    width: 100%;
}

.subtopic-section {
    border-radius: 8px; /* Rounded corners */
    border: 2px solid #ccc; /* Border color */
    background-color: #f0f0f06d; /* Background color */
    padding: 1vh;
}

.gridbox {
    border-radius: 8px; /* Rounded corners */
    border: 2px solid #ccc; /* Border color */
    background-color: #f0f0f06d; /* Background color */
    padding: 1vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subtopicTitle{
    font-weight: bold;
    font-size: 4vh;
}

.subtopic-section h3 {
    margin-bottom: 10px;
    font-size: 2vh;
    color: #333;
}

/* CSS for circles */
.circle-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.circle {
    width: 5vh;
    height: 5vh;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.circle.correct {
    background-color: green;
}

.circle.incorrect {
    background-color: red;
}

.circle.give-up {
    background-color: lightblue;
}

/* CSS for difficulty buttons */
.difficulty-buttons {
    display: flex;
    gap: 1vh;
    justify-content: center;
}

.difficulty-buttons button {
    padding: 1vh 2vh;
    font-size: 1.4vh;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.difficulty-buttons button:hover {
    background-color: #0056b3;
}

.difficulty-buttons button:focus {
    outline: none;
}

.accuracy-ring {
    position: relative;
    width: 20vh;
    height: 20vh;
    margin: 2vh auto;
  }

  .accuracy-ring svg title {
    font-size: 1vh;
    font-weight: bold;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .accuracy-ring svg {
    transform: rotate(-90deg);
  }
  
  .accuracy-ring circle {
    transition: stroke-dasharray 0.5s ease-in-out;
  }
  
  .accuracy-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.4vh;
    color: #333;
  }

.checkboxLabel{
    padding-left: 2px;
}

.checkBoxItem{
    justify-content: center;
    border-radius: 8px; /* Rounded corners */
    border: 2px solid #ccc; /* Border color */
    background-color: #b8c5ef; /* Background color */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    white-space: nowrap;
    overflow: auto;
}

.revealedSection{
    margin-top: 10vh;
}

.difficulty_button {
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 5px; /* Small rounded corners */
    border: 1px solid #ccc; /* Light gray border */
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark gray text color */
    font-size: 14px; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    margin: 5px; /* Spacing between buttons */
}

.difficulty_button:hover {
    background-color: #e0e0e0; /* Slightly darker gray background on hover */
    color: #000; /* Darker text color on hover */
}

.difficulty_button:active {
    background-color: #d0d0d0; /* Even darker gray background when active */
    border-color: #bbb; /* Darker border when active */
}

.difficulty_button:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Custom focus outline */
}

.difficulty_button.selected {
    background-color: #07005f; /* Dark background color for selected button */
    color: #fff; /* Light text color for selected button */
}