.controls .fa-icon {
    cursor: pointer;
    font-size: 3rem;
    margin: 0.5rem;
    padding: 0.5rem;
    height: 5vh;
  }

.board {
    width: 100%;
    height: 45vh;
    background-color: white;
    border-radius: 20px;
}

.whiteboardCanvas{
    width: 100%;
    height: 40vh;
    border-radius: 25px;
    border: 1px solid rgb(78, 78, 228);
}