.footerBox {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 5vh;
    background-color: #0D1117;
    text-align: right;
    justify-content: center;
    padding-right: 3vw;
    font-size: 12px;
    color: white;
    font-family: "Alexandria";
    transform: translate(0, 95vh);
    z-index: 96;
}