.navContainer{
    height: 10vh;
    width: 100%;
    background-color: #0D1117;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 100;
}
.logo{
    font-size: 3vh;
    font-weight: 600;
    font-family: "Alexandria";
    margin-left: 6px;
    color: white;
    text-decoration: none;
}
#main-logo{
    margin-left: 40px;
}
.profilepic{
    height: 5vh;
    width: 10vw;
    display: flex;
    position: absolute;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
#profileImg{
    height: 6vh;
    width: 6vh;
    border-radius: 50%;
    background-color: #b1b1b1;
    border: 2px solid grey;
}
.money_container{
    display: flex;
    flex-direction: row;
    float: right;
    float: left;
    gap: 10px;
    position: absolute;
    left: 70%;
    width: 20vw;
    height: 5.5vh;
    align-items: center;
    border-radius: 5px;
    border-color: white;
    background-color: #00AEEF;
    border-width: 1px;
}
.money_image_container{
    width: 3.5vh;
    margin-left: 0.5vh;
}
.money_amount_container{
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2.5vh;
    width: 100%;
    overflow-x: auto;
}
.ticket_container{
    display: flex;
    flex-direction: row;
    float: right;
    float: left;
    gap: 2vw;
    position: absolute;
    left: 55%;
    width: 12vw;
    height: 5.5vh;
    align-items: center;
    border-radius: 5px;
    border-color: white;
    background-color: #00AEEF;
    border-width: 1px;
    overflow-x: auto;
}
.ticket_image_container{
    width: 3.5vh;
    margin-left: 0.5vh;
}
.ticket_amount_container{
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2.5vh;
    width: 100%;
}
.logoContainer{
    margin-left: 10px;
    margin-right: 10px;
}
.logoImg{
    width: 7vh;
    height: 7vh;
}
.ProjectPart{
    background-color: transparent;
    border : none;
}
.projectPart{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 2px;
    cursor: pointer;
}
.myPosts{
    background-color: transparent;
    border: none;
    position: relative;
    top: 3px;
    cursor: pointer;
}

@media (max-width : 500px)
{
    #helper-icons{
        height: 23px;
        width: 23px;
    }
    .icons {
        display: flex;
        flex-direction: row;
        float: right;
        float: left;
        gap: 8px;
        position: absolute;
        left: 39%;
        height: 35px;
        top: 4px;
        justify-content: center;
        align-items: center;
    }
    .navContainer{
        height: 55px;
    }
    #profileImg {
        height: 39px;
        width: 39px;
    }
    .profilepic{
        position: absolute;
        right: -4px;
    }
    .logo{
        font-size: 20px;
    margin-left: -20px;

    }
    #main-logo{
        height: 25px;
        width: 25px;
        left: -25px;
        position: relative;
        top: 0px;
    }
    
}

.tempDisplay {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.buttonBox{
    display: flex;
    flex-direction: row;
    margin-right: 15vw;
    gap: 10px;
}