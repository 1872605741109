.leaderboard_window{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
    padding: 10vh;
}

.leaderboard_container{
    width: 100%;
    height: 70vh;
    overflow: auto;
    background-color: white;
    border: 1px solid rgb(104, 104, 104);
    border-radius: 25px;
    text-align: center;
    align-items: center;
}

.leader_title{
    padding: 10px;
    font-size:48px;
    font-family: Impact;
}

.leaderboard_item{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 8px 16px;
    border-radius: 8px; /* Rounded corners */
    border: 2px solid #ccc; /* Border color */
    background-color: #f0f0f0; /* Background color */
    margin-bottom: 3px;
}

.rank {
    flex: 0 0 200px; /* Adjust width as needed */
    text-align: left;
    padding-left: 10px;
}

.username {
    flex: 1; /* Takes remaining space */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px; /* Optional: Add margin to separate from money */
}

.money {
    flex: 0 0 200px; /* Adjust width as needed */
    text-align: right;
    padding-right: 10px;
    overflow: auto;
}