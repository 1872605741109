.question_container{
    width: 70vw;
    height: 75vh;
    background-color: white;
    border: 1px solid rgb(104, 104, 104);
    border-radius: 25px;
    overflow: y;
}
.question_header{
    color: black;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 40px;
    font-weight: 550;
}
.question_box{
    display: flex;
    height: 85%;
    width: 100%;
    align-items: center;
    flex-direction: row;
    color: black;
    font-size: 2vh;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    white-space: nowrap;
}

.questionSide{
    display: flex;
    height: 65vh;
    width: 100%;
    flex-direction: column;
    font-size: 15px;
}

.answerSide{
    display: flex;
    height: 65vh;
    width: 100%;
    flex-direction: column;
}

.question-prompt{
    height: 100%;
    width: 100%;
    padding: 3vh;
    font-size: 2vh;
    word-wrap: break-word;
    overflow: auto;
    white-space: normal;
    align-items: flex-end;
}

.answer-choices{
    height:100%;
    width:100%;
    padding: 3vh;
    font-size: 2vh;
    word-wrap: break-word;
    overflow: auto;
    white-space: normal;
}

.answer-box{
    height: 100%;
    width:100%;
    padding: 2vh;
    font-size: 2vh;
    word-wrap: break-word;
    overflow: auto;
    white-space: normal;
}

SVG{
    display:inline-block;
    align-items: center;
    margin-bottom: 5px;
}

.question_box ul {
    list-style: none;
    counter-reset: letter;
}

.question_box ul li {
    counter-increment: letter;
}

.question_box ul li::marker {
    content: counter(letter, upper-alpha) ". ";
    font-weight: bold;
    color: black;
}

.question_explanation{
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 10vh;
    margin-bottom: 7vh;
}

.question_title{
    font-size: 30px;
    font-weight: bold;
    text-decoration: underline;
}

.questionTable {
    width: 80%;
    border: 1px solid white;
    margin-bottom: 30px;
    background-color: #bbbbbb;
}

.questionTableElem {
    border: 1px solid white;
}

.questionPgph {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    font-family: "BoldPrice";
}

.whiteboard_container {
    width: 800px;
    height: 600px;
    background-color: white;
}

.entire_container {
    display: flex;
    flex-direction: column;
    margin-top: 0vh;
}

.whiteboard_toggle {
    display: flex;
    align-items:right;
    padding: 10px;
    height: 5vh;
    background-color: white;
}

.total_white {
    height: 50vh;
    width: 100%;
    background-color: white;
}

.iconpad{
    height: 30px;
    width: 30px;
    margin-left: 20px;
}