.crash_main_window{
    transform: translate(0%, 10vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65vh;
    gap: 15px;
}

.crash_window{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}
.crash_container{
    width: 100vh;
    height: 50vh;
    background-color: rgb(10, 1, 81);
    border: 1px solid rgb(104, 104, 104);
    border-radius: 25px;
}

#canvas1 {
    width:100%;
    height:100%;
    /* background-color: red; */
}

.counter{
    width:200px;
    height:100px;
    background-color: gray;
    transform: translate(-50%, -50%);
}

.betting-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    color: black;
}

input[type="number"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.submit_button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.submit_button:hover {
    background-color: #0056b3;
}

#message {
    margin-top: 10px;
    color: red;
    font-size: 14px;
}

.cashout {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.cashout:hover {
    background-color: #0056b3;
}

.crash_explanation{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 7vh;
}

.crash_title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
}