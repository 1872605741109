.mainpage_container{
    width: 70vw;
    height: 70vh;
    padding-top: 10vh;
}
.mainpage_header{
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 40px;
    font-weight: 550;
}
.mainpage_box{
    display: flex;
    height: 540px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    background-color: #161B22;
    background-color: #131313;
    background-color: transparent;
    border: 1px solid rgb(104, 104, 104);
    border-radius: 25px;
}