.answer-explanation{
    word-wrap: break-word;
    overflow: auto;
    white-space: normal;
}

.answerButton {
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 5px; /* Small rounded corners */
    border: 1px solid #ccc; /* Light gray border */
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark gray text color */
    font-size: 2vh; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    margin: 5px; /* Spacing between buttons */
}

.answerButton:hover {
    background-color: #e0e0e0; /* Slightly darker gray background on hover */
    color: #000; /* Darker text color on hover */
}

.answerButton:active {
    background-color: #d0d0d0; /* Even darker gray background when active */
    border-color: #bbb; /* Darker border when active */
}

.answerButton:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Custom focus outline */
}

.answerButton.selected {
    background-color: #666; /* Dark background color for selected button */
    color: #fff; /* Light text color for selected button */
}

.cb-font-weight-bold {
    font-weight: bold;
}

.submitButton {
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 5px; /* Small rounded corners */
    border: 1px solid #ccc; /* Light gray border */
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark gray text color */
    font-size: 2vh; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    margin: 5px; /* Spacing between buttons */
}

.submitButton:hover {
    background-color: #e0e0e0; /* Slightly darker gray background on hover */
    color: #000; /* Darker text color on hover */
}

.submitButton:active {
    background-color: #d0d0d0; /* Even darker gray background when active */
    border-color: #bbb; /* Darker border when active */
}

.submitButton:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Custom focus outline */
}

.answer_validity {
    font-weight: bold;
    margin-bottom: 5px;
}

.mode-selection{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.betChoice{
    display:flex;
    flex-direction: column;
    gap:10px;
}

.modeButton {
    padding: 5px 10px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    background-color: #00AEEF;
    color: white;
    font-size: 2vh;
    cursor: pointer;
    align-items: center;
}

.modeButton.selected {
    border: 4px solid purple; /* Change color and thickness as needed */
}

.giveUpButton{
    padding: 10px 20px; /* Adjust padding as needed */
    border-radius: 5px; /* Small rounded corners */
    border: 1px solid #ccc; /* Light gray border */
    background-color: #f0f0f0; /* Light gray background */
    color: #333; /* Dark gray text color */
    font-size: 2vh; /* Font size */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
    margin: 5px; /* Spacing between buttons */
}

.giveUpButton:hover {
    background-color: #e0e0e0; /* Slightly darker gray background on hover */
    color: #000; /* Darker text color on hover */
}

.giveUpButton:active {
    background-color: #d0d0d0; /* Even darker gray background when active */
    border-color: #bbb; /* Darker border when active */
}

.giveUpButton:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Custom focus outline */
}