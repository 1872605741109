.ProjectPart{
    background-color: transparent;
    border : none;
    align-items: center;
}

.projectPart{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.sidebar_container {
    width: 100%;
}

.sidebar_container ul {
    list-style: none;
    padding-top: 20px;
    margin: 0;
    width: 100%;
}

.sidebar_container ul li {
    margin-bottom: 0;
}

.sidebar_container ul li button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
}

.sidebar_container ul li button .projectPart {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    height: 48px;
    border-radius: 15px;
    color: white;
    transition: background-color 0.3s ease;
}

.textportion {
    text-align: right;
    margin-right: 10px;
}

.iconportion{
    text-align: left;
    margin-left: 10px;
}

.sidebar_container ul li button .projectPart:hover {
    background-color: #e0e0e01e;
}

.sidebar_container ul li button .projectPart svg {
    width: 24px;
    height: 24px;
    fill: #333;
}

.titleSeparator {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 33px;
    color: #e0e0e01e;
    text-decoration: underline;
    background-color: #161B22;
    border-radius: 10px;
}

.group {
    display: flex;
    flex-direction: column;
    background-color: #1e2c38;
    margin-bottom: 10px;
    border-radius: 5px;
}

.group-header {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: left;
    padding-left: 10px;
    text-align: left;
    margin-bottom: 10px; /* Adjust spacing as needed */
}

.group-header h2 {
    margin: 0;
    font-size: 18px;
    color: white; /* Header text color */
    justify-content: left;
}

.line {
    flex-grow: 1;
    width: 90%;
    height: 2px;
    background-color: #344553; /* Line color */
    justify-content: center;
}