.plinko_main_window{
    transform: translate(-10%, 0%);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.plinko_window{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
    padding: 0px;
}
.p5Canvas{
    margin: 2vh;
    border: 2px solid rgb(104, 104, 104);
    background-color: rgb(16, 32, 45);
    border-radius: 25px;
}

.react-p5-wrapper{
    scale: 0.9;
    max-width: 40vh;
    margin: 0px;
}

#canvas1 {
    width:100%;
    height:100%;
    /* background-color: red; */
}

.counter{
    width:200px;
    height:100px;
    background-color: gray;
    transform: translate(-50%, -50%);
}

.betting-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    color: black;
    margin: 0px;
}

.plinko_explanation{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translate(18vw, -5vh);
    margin-bottom: 2vh;
}

.plinko_title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
}

.slider-container{
    width: 100%;
}
.slider {
    width: 100%;
}